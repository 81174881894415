.welcome-container {
    max-width: 1200px;
    margin: 2rem auto;
    padding: 0 1rem;
  }
  
  .welcome-card {
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    margin-top: 2rem;
  }
  
  .welcome-title {
    color: #1a365d;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 1.5rem;
    margin: 2rem 0;
  }
  
  .feature-card {
    background: #f8fafc;
    border-radius: 8px;
    padding: 1.5rem;
    transition: transform 0.2s ease;
  }
  
  .feature-card:hover {
    transform: translateY(-2px);
  }
  
  .feature-icon {
    color: #3182ce;
    margin-bottom: 1rem;
  }
  
  .feature-title {
    color: #2d3748;
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  .feature-description {
    color: #4a5568;
    font-size: 0.875rem;
    line-height: 1.5;
  }
  
  .process-section {
    background: #ebf8ff;
    border-radius: 8px;
    padding: 1.5rem;
    margin: 2rem 0;
  }
  
  .process-title {
    color: #2c5282;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  .process-step {
    display: flex;
    align-items: flex-start;
    margin: 1rem 0;
  }
  
  .step-number {
    background: #3182ce;
    color: white;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    margin-right: 1rem;
    flex-shrink: 0;
  }
  
  .step-text {
    color: #4a5568;
    font-size: 0.875rem;
    line-height: 1.5;
  }
  
  .id-section {
    background: #f7fafc;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    padding: 1rem;
    margin: 2rem 0;
  }
  
  .id-text {
    color: #4a5568;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }
  
  .id-value {
    font-family: monospace;
    font-weight: 500;
    color: #2d3748;
  }
  
  .start-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #3182ce;
    color: white;
    padding: 0.75rem 2rem;
    border-radius: 8px;
    font-weight: 600;
    transition: background-color 0.2s ease;
    margin: 2rem auto;
    border: none;
    cursor: pointer;
    gap: 0.5rem;
  }
  
  .start-button:hover {
    background: #2c5282;
  }
  
  @media (max-width: 768px) {
    .welcome-card {
      padding: 1.5rem;
    }
  
    .welcome-title {
      font-size: 1.5rem;
    }
  
    .features-grid {
      grid-template-columns: 1fr;
    }
  }