/* TaskManagementView.css */
.task-management-view {
    display: flex;
    gap: 20px;
  }
  
  .task-list-container {
    flex: 1;
  }
  
  .task-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .add-task-button {
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .task-list {
    margin-top: 10px;
  }
  
  .task-item {
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 4px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  .task-item:hover {
    background-color: #e9ecef;
  }
  
  .task-details-container {
    flex: 1;
  }
  
  .no-task-selected {
    text-align: center;
    padding: 20px;
    background-color: #f1f1f1;
    border-radius: 4px;
  }
  
  .task-form-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }