/* src/Components/SummaryView.css */
.summary-view {
    padding: 20px;
  }
  
  .summary-view .card {
    margin-bottom: 20px;
    border: none;
    border-radius: 8px;
    padding: 20px; /* Added padding */
  }
  
  .summary-view .card-header {
    font-weight: bold;
    font-size: 1.25rem;
  }
  
  .summary-view .card-title {
    font-size: 1.1rem;
    color: #6c757d; /* Muted color for less aggressive text */
  }
  
  .summary-view .card-text {
    font-size: 1rem;
    color: #6c757d; /* Muted color for less aggressive text */
  }
  
  .summary-view .task-progress-body {
    padding: 20px; /* Added padding */
  }
  
  .summary-view .task-progress-bar {
    height: 1.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    
    border-radius: 5px;
  }
/* src/Components/SummaryView.css */
.summary-view {
    padding: 20px;
  }
  
  .summary-view .card {
    margin-bottom: 20px;
    border: none;
    border-radius: 8px;
    padding: 20px; /* Added padding */
  }
  
  .summary-view .card-header {
    font-weight: bold;
    font-size: 1.25rem;
  }
  
  .summary-view .card-title {
    font-size: 1.1rem;
    color: #6c757d; /* Muted color for less aggressive text */
  }
  
  .summary-view .card-text {
    font-size: 1rem;
    color: #6c757d; /* Muted color for less aggressive text */
  }
  
  .summary-view .task-progress-body {
    padding: 20px; /* Added padding */
  }
  
  .summary-view .task-progress-bar {
    height: 1.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    border-radius: 5px;
  }
  
  /* Additional styling for updates card to take full width */
  .summary-view .card.w-100 {
    width: 100%;
  }
  