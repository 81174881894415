.lenders-view {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.lenders-view h1 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 1.5rem;
}

.lenders-actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.add-lender-btn,
.filter-lenders-btn {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  color: #fff;
  background-color: #4a90e2;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.add-lender-btn:hover,
.filter-lenders-btn:hover {
  background-color: #3a7bc8;
}

.lenders-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.lender-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.lender-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.lender-card h3 {
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 1rem;
}

.lender-card p {
  margin-bottom: 0.5rem;
  color: #666;
}

.lender-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.lender-actions button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: #4a90e2;
  margin-left: 1rem;
  transition: color 0.3s ease;
}

.lender-actions button:hover {
  color: #3a7bc8;
}

@media (max-width: 768px) {
  .lenders-view {
    padding: 1rem;
  }

  .lenders-actions {
    flex-direction: column;
    gap: 1rem;
  }

  .add-lender-btn,
  .filter-lenders-btn {
    width: 100%;
  }
}