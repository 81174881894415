/* FinancialItemForm.css */

.financial-item-row {
    margin-bottom: 15px;
  }
  
  .financial-item-input {
    background-color: #f9f9f9;
    border-radius: 5px;
  }
  
  .financial-item-input:focus {
    background-color: #fff;
  }
  
  .input-group-text {
    background-color: #e9ecef;
    border-radius: 5px 0 0 5px;
  }
  
  .text-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .financial-item-row .btn-danger {
    padding: 6px 10px;
  }
  
  @media (max-width: 768px) {
    .financial-item-row {
      flex-direction: column;
    }
    .financial-item-row > div {
      margin-bottom: 10px;
    }
  }
  