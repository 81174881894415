.broker-settings {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .broker-settings h2 {
    color: #2c3e50;
    margin-bottom: 1.5rem;
  }
  
  .broker-settings .card {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: none;
  }
  
  .broker-settings .form-group {
    margin-bottom: 1.5rem;
  }
  
  .broker-settings label {
    font-weight: 600;
    color: #34495e;
  }
  
  .broker-settings .form-control {
    border-radius: 0.25rem;
  }
  
  .broker-settings .dropzone {
    border: 2px dashed #3498db;
    border-radius: 0.25rem;
    padding: 2rem;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .broker-settings .dropzone:hover,
  .broker-settings .dropzone.active {
    background-color: #ecf0f1;
  }
  
  .broker-settings .dropzone p {
    margin: 0;
    color: #7f8c8d;
  }
  
  .broker-settings .broker-logo {
    max-width: 200px;
    max-height: 200px;
    object-fit: contain;
    margin-top: 1rem;
  }
  
  .broker-settings .btn-primary {
    background-color: #3498db;
    border-color: #3498db;
    transition: all 0.3s ease;
  }
  
  .broker-settings .btn-primary:hover {
    background-color: #2980b9;
    border-color: #2980b9;
  }
  
  .broker-settings .progress {
    height: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .broker-settings .alert {
    margin-top: 1rem;
  }
  
  @media (max-width: 768px) {
    .broker-settings {
      padding: 1rem;
    }
  }