/* Declarations.css */
.declarations-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    color: #333;
    margin-bottom: 30px;
    text-align: center;
    font-size: 24px;
  }
  
  .declaration-item {
    margin-bottom: 30px;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    transition: all 0.3s ease;
  }
  
  .declaration-item:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .declaration-item p {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
  }
  
  .toggle-container {
    display: flex;
    justify-content: space-between;
  }
  
  .toggle-button {
    flex: 1;
    padding: 10px;
    border: none;
    background-color: #e9ecef;
    color: #495057;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .toggle-button:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  
  .toggle-button:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  
  .toggle-button.active {
    background-color: #4a90e2;
    color: white;
  }
  
  .toggle-button .icon {
    margin-right: 8px;
  }
  
  .button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }
  
  .button {
    padding: 12px 24px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    transition: all 0.3s ease;
  }
  
  .button.primary {
    background-color: #4a90e2;
    color: white;
  }
  
  .button.primary:hover {
    background-color: #3a7bc8;
  }
  
  .button.secondary {
    background-color: #f0f0f0;
    color: #333;
  }
  
  .button.secondary:hover {
    background-color: #e0e0e0;
  }
  
  @media (max-width: 600px) {
    .declarations-container {
      padding: 20px;
    }
  
    .toggle-container {
      flex-direction: column;
    }
  
    .toggle-button {
      margin-bottom: 10px;
      border-radius: 4px;
    }
  
    .button-group {
      flex-direction: column-reverse;
    }
  
    .button {
      width: 100%;
      margin-bottom: 10px;
    }
  }