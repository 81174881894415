/* RentRoll.css */

.rent-roll-container {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .rent-roll-actions {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
  
  .add-entry {
    display: inline-flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    text-decoration: none;
  }
  
  .add-entry svg {
    margin-right: 5px;
  }
  
  .csv-actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .csv-buttons {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .csv-btn {
    display: inline-flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #4a90e2;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    text-decoration: none;
  }
  
  .csv-btn svg {
    margin-right: 5px;
  }
  
  .upload-btn {
    background-color: #4caf50;
  }
  
  .format-toggle {
    background: none;
    border: none;
    color: #4a90e2;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
  }
  
  .format-toggle svg {
    margin-right: 5px;
  }
  
  .format-info {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 15px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  
  .format-info h4 {
    margin-top: 0;
    margin-bottom: 10px;
  }
  
  .format-info ul {
    padding-left: 20px;
    margin-bottom: 10px;
  }
  
  .format-info p {
    margin-bottom: 0;
  }
  
  .rent-roll-table-container {
    overflow-x: auto;
    max-width: 100%;
  }
  
  .rent-roll-table {
    width: 100%;
    min-width: 1000px;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .rent-roll-table th, 
  .rent-roll-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
    white-space: nowrap;
  }
  
  .rent-roll-table th {
    background-color: #f2f2f2;
    font-weight: bold;
    position: sticky;
    top: 0;
    z-index: 10;
  }
  
  .rent-roll-table input {
    width: 100%;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: transparent;
  }
  
  .remove-entry {
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .rent-roll-summary {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 20px;
  }
  
  .button-group {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .button.primary {
    background-color: #4a90e2;
    color: white;
  }
  
  .button.secondary {
    background-color: #f0f0f0;
    color: #333;
  }
  
  .input-with-prefix {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  
  .input-with-prefix .prefix {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    color: #666;
  }
  
  .input-with-prefix input {
    padding-left: 20px;
  }
  
  .rent-roll-table input[type="number"],
  .rent-roll-table input[type="text"] {
    width: 100%;
    padding: 5px 5px 5px 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .rent-roll-table .react-datepicker-wrapper {
    width: 100%;
  }
  
  .rent-roll-table .react-datepicker__input-container input {
    width: 100%;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  @media (max-width: 768px) {
    .rent-roll-actions {
      flex-direction: column;
      align-items: stretch;
    }
  
    .csv-actions {
      align-items: stretch;
      margin-top: 10px;
    }
  
    .csv-buttons {
      flex-direction: column;
    }
  
    .csv-btn, 
    .format-toggle {
      width: 100%;
      justify-content: center;
    }
  
    .rent-roll-table th, 
    .rent-roll-table td {
      padding: 8px;
    }
  
    .button-group {
      flex-direction: column;
    }
  
    .button {
      width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .rent-roll-container {
      padding: 10px;
    }
  
    .rent-roll-table th, 
    .rent-roll-table td {
      padding: 6px;
      font-size: 14px;
    }
  }