.finance-request-detail {
  font-family: var(--font-family);
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--background-color);
}

.finance-request-detail .container {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 20px;
}

.finance-request-detail .hero {
  padding: 4rem 0;
  text-align: center;
  background: linear-gradient(135deg, var(--primary-color), var(--accent-color));
  color: var(--white);
}

.finance-request-detail .hero h1 {
  font-size: 2.8rem;
  font-weight: 700;
  margin-bottom: 1rem;
  line-height: 1.2;
}

.finance-request-detail .section {
  padding: 40px 0;
  background-color: var(--white);
}

.finance-request-detail .section h2 {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 10px;
}

.finance-request-detail .section h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 3px;
  background-color: var(--accent-color);
}

.finance-request-detail .card {
  background: var(--white);
  border-radius: var(--border-radius);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 25px;
  margin-bottom: 25px;
  transition: box-shadow var(--transition), transform var(--transition);
}

.finance-request-detail .card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transform: translateY(-5px);
}

.finance-request-detail .card h3 {
  font-size: 1.5rem;
  color: var(--secondary-color);
  margin-bottom: 20px;
}

.finance-request-detail .info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.finance-request-detail .info-item {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: var(--light-gray);
  border-radius: var(--border-radius);
}

.finance-request-detail .info-icon {
  font-size: 1.5rem;
  color: var(--primary-color);
  margin-right: 15px;
}

.finance-request-detail table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.finance-request-detail th,
.finance-request-detail td {
  padding: 15px;
  text-align: left;
}

.finance-request-detail th {
  background-color: var(--primary-color);
  color: var(--white);
  font-weight: bold;
}

.finance-request-detail tr:nth-child(even) {
  background-color: var(--light-gray);
}

.finance-request-detail .status-badge {
  display: inline-block;
  padding: 8px 16px;
  border-radius: 20px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.9rem;
  margin-bottom: 20px;
}

.finance-request-detail .status-pending { background-color: #FFA500; color: var(--white); }
.finance-request-detail .status-in-review { background-color: var(--accent-color); color: var(--white); }
.finance-request-detail .status-approved { background-color: #36B37E; color: var(--white); }
.finance-request-detail .status-declined { background-color: #FF5630; color: var(--white); }

@media (max-width: 768px) {
  .finance-request-detail .hero h1 {
    font-size: 2.2rem;
  }

  .finance-request-detail .section h2 {
    font-size: 1.8rem;
  }

  .finance-request-detail .info-grid {
    grid-template-columns: 1fr;
  }
}