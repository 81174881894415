/* MortgageList.css */
:root {
    --primary-color: #0052cc;
    --secondary-color: #172b4d;
    --accent-color: #0065ff;
    --background-color: #f4f5f7;
    --text-color: #172b4d;
    --light-gray: #f4f5f7;
    --white: #ffffff;
    --border-radius: 8px;
    --transition: all 0.3s ease;
    --max-width: 1200px;
  }
  
  .ml-container {
    max-width: var(--max-width);
    margin: 0 auto;
    padding: 2rem;
  }
  
  .ml-title {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .ml-filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
  }
  
  .ml-search {
    position: relative;
    flex-grow: 1;
    margin-right: 1rem;
  }
  
  .ml-search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--secondary-color);
  }
  
  .ml-search input {
    width: 100%;
    padding: 0.5rem 0.5rem 0.5rem 2.5rem;
    border: 1px solid var(--light-gray);
    border-radius: var(--border-radius);
    font-size: 1rem;
  }
  
  .ml-status-filter {
    padding: 0.5rem;
    border: 1px solid var(--light-gray);
    border-radius: var(--border-radius);
    font-size: 1rem;
    color: var(--text-color);
  }
  
  .ml-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
  }
  
  .ml-card {
    background-color: var(--white);
    border-radius: var(--border-radius);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    transition: var(--transition);
  }
  
  .ml-card:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .ml-card-title {
    font-size: 1.2rem;
    color: var(--primary-color);
    margin-bottom: 0.5rem;
  }
  
  .ml-card-borrower {
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: var(--text-color);
    margin-bottom: 0.5rem;
  }
  
  .ml-status {
    display: inline-block;
    padding: 0.25rem 0.5rem;
    border-radius: 12px;
    font-size: 0.9rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .ml-status-pending { background-color: #fff8e1; color: #ffa000; }
  .ml-status-submitted { background-color: #e3f2fd; color: #1976d2; }
  .ml-status-declined { background-color: #ffebee; color: #d32f2f; }
  .ml-status-approved { background-color: #e8f5e9; color: #388e3c; }
  .ml-status-default { background-color: #fafafa; color: #757575; }
  
  .ml-card-details {
    margin-bottom: 1rem;
  }
  
  .ml-card-details p {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .ml-icon {
    margin-right: 0.5rem;
    color: var(--primary-color);
  }
  
  .ml-progress-bar {
    height: 8px;
    background-color: var(--light-gray);
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 0.5rem;
  }
  
  .ml-progress {
    height: 100%;
    background-color: var(--primary-color);
    transition: width 0.3s ease;
  }
  
  .ml-progress-text {
    font-size: 0.9rem;
    color: var(--text-color);
    margin-bottom: 1rem;
  }
  
  .ml-view-button {
    width: 100%;
    padding: 0.5rem;
    background-color: var(--primary-color);
    color: var(--white);
    border: none;
    border-radius: var(--border-radius);
    font-size: 1rem;
    cursor: pointer;
    transition: var(--transition);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .ml-view-button:hover {
    background-color: var(--accent-color);
  }
  
  /* FullMortgageView.css */
  .fmv-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .fmv-content {
    background-color: var(--white);
    border-radius: var(--border-radius);
    width: 90%;
    max-width: 1000px;
    max-height: 90vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
  
  .fmv-header {
    padding: 1rem;
    background-color: var(--primary-color);
    color: var(--white);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .fmv-header h1 {
    font-size: 1.5rem;
    margin: 0;
  }
  
  .fmv-close {
    background: none;
    border: none;
    color: var(--white);
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .fmv-body {
    display: flex;
    flex-grow: 1;
  }
  
  .fmv-sidebar {
    width: 200px;
    background-color: var(--light-gray);
    padding: 1rem;
  }
  
  .fmv-nav-button {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    background: none;
    border: none;
    text-align: left;
    cursor: pointer;
    transition: var(--transition);
  }
  
  .fmv-nav-button:hover,
  .fmv-nav-button.active {
    background-color: var(--accent-color);
    color: var(--white);
  }
  
  .fmv-main {
    flex-grow: 1;
    padding: 1rem;
  }
  
  .fmv-section {
    margin-bottom: 2rem;
  }
  
  .fmv-section h2 {
    font-size: 1.5rem;
    color: var(--primary-color);
    margin-bottom: 1rem;
  }
  
  .fmv-overview-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
  }
  
  .fmv-overview-item {
    display: flex;
    align-items: center;
  }
  
  .fmv-footer {
    padding: 1rem;
    background-color: var(--light-gray);
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
  
  .fmv-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: var(--border-radius);
    font-size: 1rem;
    cursor: pointer;
    transition: var(--transition);
    display: flex;
    align-items: center;
  }
  
  .fmv-button.approve { background-color: #4caf50; color: white; }
  .fmv-button.decline { background-color: #f44336; color: white; }
  .fmv-button.export { background-color: var(--primary-color); color: white; }
  .fmv-button.reopen { background-color: #ff9800; color: white; }
  
  .fmv-button:hover {
    opacity: 0.9;
  }
  
  @media (max-width: 768px) {
    .ml-filters {
      flex-direction: column;
    }
  
    .ml-search {
      margin-right: 0;
      margin-bottom: 1rem;
    }
  
    .fmv-body {
      flex-direction: column;
    }
  
    .fmv-sidebar {
      width: 100%;
    }
  
    .fmv-footer {
      flex-direction: column;
    }
  
    .fmv-button {
      width: 100%;
    }
  }