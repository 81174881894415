/* GetStarted.css */
:root {
  --primary-color: #4285F4;
  --accent-color: #34A853;
  --white: #FFFFFF;
  --light-bg: #E8F0FE;
  --text-color: #333333;
}

.get-started-page {
  padding: 60px 0;
  background-color: var(--primary-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container {
  width: 100%;
  max-width: 700px; /* Reduced from 600px */
  margin: 0 auto;
  padding: 0 20px;
}

.get-started-page h1 {
  color: var(--white);
  font-size: 3rem;
  margin-bottom: 1rem;
  text-align: center;
  font-weight: bold;
}

.get-started-page p {
  color: var(--white);
  font-size: 1.2rem;
  margin-bottom: 2rem;
  text-align: center;
}

.get-started-form {
  background-color: var(--white);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px; /* Added max-width for the form */
  margin: 0 auto; /* Center the form */
}

  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: var(--text-color);
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
  }
  
  .form-group input:focus,
  .form-group textarea:focus {
    border-color: var(--primary-color);
    outline: none;
    box-shadow: 0 0 0 2px rgba(66, 133, 244, 0.2);
  }
  
  .user-type-options {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
  
  .user-type-btn {
    flex: 1;
    padding: 10px;
    border: 2px solid var(--primary-color);
    background-color: var(--white);
    color: var(--primary-color);
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .user-type-btn.active {
    background-color: var(--primary-color);
    color: var(--white);
  }
  
  .submit-btn {
    width: 100%;
    padding: 12px;
    font-size: 1.1rem;
    margin-top: 20px;
    background-color: var(--primary-color);
    color: var(--white);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-btn:hover {
    background-color: #3367D6;
  }
  
  .submit-btn:disabled {
    background-color: #B0B0B0;
    cursor: not-allowed;
  }
  
  /* Icon styles */
  .form-group label svg {
    margin-right: 8px;
  }
  
  .error-message {
    color: #721c24;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    padding: 12px;
    margin-bottom: 20px;
    border-radius: 5px;
  }
  
  .success-message {
    color: var(--text-color);
    background-color: var(--light-bg);
    border: 1px solid #C2E7FF;
    padding: 30px;
    margin-top: 20px;
    border-radius: 10px;
    text-align: center;
  }
  
  .success-message h2 {
    color: var(--accent-color);
    margin-bottom: 10px;
  }
  
  .checkmark-circle {
    width: 80px;
    height: 80px;
    background-color: var(--accent-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 20px;
    animation: scale-in 0.5s ease-out;
  }
  
  .checkmark {
    color: white;
    font-size: 40px;
    animation: check-appear 0.5s ease-out 0.5s both;
  }
  
  .back-to-home-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    margin-top: 20px;
    background-color: var(--white);
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
    border-radius: 5px;
    font-weight: bold;
    text-decoration: none;
    transition: all 0.3s ease;
  }
  
  .back-to-home-btn:hover {
    background-color: var(--primary-color);
    color: var(--white);
  }
  
  .back-to-home-btn svg {
    margin-right: 8px;
  }
  
  .success-message .back-to-home-btn {
    margin-top: 30px;
  }
  
  @keyframes scale-in {
    0% { transform: scale(0); }
    100% { transform: scale(1); }
  }
  
  @keyframes check-appear {
    0% { opacity: 0; transform: scale(0); }
    100% { opacity: 1; transform: scale(1); }
  }
  
  .darkText {
    color: #333333 !important;
    font-weight: bold;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .get-started-page {
      padding: 40px 0;
    }
  
    .get-started-form {
      padding: 20px;
    }
  
    .user-type-options {
      flex-direction: column;
    }
  
    .back-to-home-btn {
      width: 100%;
      margin-top: 15px;
    }
  }