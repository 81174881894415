:root {
    --primary-color: #1a73e8;
    --secondary-color: #5f6368;
    --background-color: #f8f9fa;
    --card-background: #ffffff;
    --border-color: #dadce0;
    --text-color: #202124;
    --success-color: #34a853;
    --warning-color: #fbbc05;
    --danger-color: #ea4335;
    --font-family: 'Roboto', sans-serif;
  }
  
  .customers-view {
    position: relative;
    padding: 0.1rem;
    font-family: var(--font-family);
    color: var(--text-color);
    
  }
  
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
    z-index: 1000;
    backdrop-filter: blur(5px);
  }
  
  .card {
    border: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
  }
  
  .card:hover {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  }
  
  .customers-table {
    background-color: var(--card-background);
    border-radius: 8px;
    overflow: hidden;
  }
  
  .customers-table th {
    background-color: #f0f2f5;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.85rem;
    color: var(--secondary-color);
    padding: 1rem;
    border-top: none;
  }
  
  .customers-table td {
    vertical-align: middle;
    padding: 1rem;
  }
  
  .customer-name {
    color: var(--primary-color);
    text-decoration: none;
    font-weight: 500;
    transition: color 0.2s ease;
    cursor: pointer;
  }
  
  .customer-name:hover {
    color: #174ea6;
  }
  
  .search-bar {
    position: relative;
    margin-bottom: 1.5rem;
  }
  
  .search-icon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: var(--secondary-color);
  }
  
  .search-bar input {
    padding: 0.75rem 1rem 0.75rem 2.5rem;
    border-radius: 25px;
    border: 1px solid #ced4da;
    font-size: 1rem;
    width: 100%;
    transition: box-shadow 0.2s ease, border-color 0.2s ease;
  }
  .search-bar input:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(26, 115, 232, 0.2);
    border-color: var(--primary-color);
  }
  
  .btn-primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    transition: background-color 0.2s ease, transform 0.1s ease;
  }
  
  .btn-primary:hover {
    background-color: #174ea6;
    border-color: #174ea6;
    transform: translateY(-1px);
  }
  
  .btn-primary:active {
    transform: translateY(0);
  }
  
  .modal-content {
    border-radius: 8px;
    border: none;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  }
  
  .modal-header {
    background-color: #f0f2f5;
    border-bottom: none;
    padding: 1.5rem;
  }
  
  .modal-title {
    font-weight: 600;
    color: var(--secondary-color);
  }
  
  .modal-body {
    padding: 1.5rem;
  }
  
  .form-group label {
    font-weight: 500;
    color: var(--secondary-color);
    margin-bottom: 0.5rem;
  }
  
  .form-control {
    border-radius: 4px;
    padding: 0.75rem 1rem;
    border: 1px solid #ced4da;
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
  }
  
  .form-control:focus {
    box-shadow: 0 0 0 3px rgba(26, 115, 232, 0.2);
    border-color: var(--primary-color);
  }
  
  .alert-danger {
    color: var(--danger-color);
    background-color: #fdeaea;
    border-color: #fad7d7;
    border-radius: 4px;
    padding: 1rem;
  }
  
  .btn-sm {
    padding: 0.4rem 0.6rem;
    font-size: 0.875rem;
  }
  
  .rounded-circle {
    width: 2.5rem;
    height: 2.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease, color 0.2s ease;
  }
  
  .btn-info.rounded-circle {
    background-color: #e8f0fe;
    color: var(--primary-color);
    border: none;
  }
  
  .btn-info.rounded-circle:hover {
    background-color: #d2e3fc;
  }
  
  .btn-primary.rounded-circle {
    background-color: var(--primary-color);
    color: white;
  }
  
  .btn-primary.rounded-circle:hover {
    background-color: #174ea6;
  }
  
  .btn-danger.rounded-circle {
    background-color: #fdeaea;
    color: var(--danger-color);
    border: none;
  }
  
  .btn-danger.rounded-circle:hover {
    background-color: #fad7d7;
  }
  
  @media (max-width: 768px) {
    .customers-view {
      padding: 1rem;
    }
  
    .customers-table th,
    .customers-table td {
      padding: 0.75rem;
    }
  
    .btn-sm {
      padding: 0.3rem 0.5rem;
      font-size: 0.8rem;
    }
  
    .rounded-circle {
      width: 2rem;
      height: 2rem;
    }
  }