/* FinanceRequestForm.css */
:root {
  --primary-color: #0052cc;
  --secondary-color: #172b4d;
  --accent-color: #0065ff;
  --background-color: #f4f5f7;
  --text-color: #172b4d;
  --light-gray: #f4f5f7;
  --white: #ffffff;
  --border-radius: 8px;
  --transition: all 0.3s ease;
  --font-family: 'Arial', sans-serif;
  --max-width: 1200px;
}

.finance-request-page {
  background-color: var(--background-color);
  min-height: 100vh;
  font-family: var(--font-family);
}

.finance-request-container {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 20px;
}

.finance-request-form {
  background-color: var(--white);
  border-radius: var(--border-radius);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  position: relative;
}

.form-title {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 20px;
  text-align: center;
}

.step-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: nowrap;
}

.step-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  min-width: 0; /* Allows flex items to shrink below their minimum content size */
  padding: 0 5px;
  cursor: pointer;
  transition: var(--transition);
}

.step-icon {
  font-size: 24px;
  color: var(--secondary-color);
  margin-bottom: 5px;
}

.step-name {
  font-size: 12px;
  color: var(--secondary-color);
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.step-indicator.active .step-icon,
.step-indicator.completed .step-icon {
  color: var(--accent-color);
}

.step-indicator.active .step-name {
  font-weight: bold;
  color: var(--accent-color);
}

.form-step {
  background-color: var(--white);
  border-radius: var(--border-radius);
  padding: 20px;
  margin-top: 20px;
}

.scroll-indicator {
  display: none;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: var(--accent-color);
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

/* Responsive styles */
@media (max-width: 1024px) {
  .step-icon {
    font-size: 20px;
  }

  .step-name {
    font-size: 10px;
  }
}

@media (max-width: 768px) {
  .finance-request-container {
    padding: 10px;
  }

  .finance-request-form {
    padding: 15px;
  }

  .form-title {
    font-size: 1.5rem;
  }

  .step-navigation {
    justify-content: flex-start;
    overflow-x: auto;
    padding-bottom: 10px;
    margin-bottom: 20px;
    scrollbar-width: thin;
    scrollbar-color: var(--accent-color) var(--light-gray);
  }

  .step-navigation::-webkit-scrollbar {
    height: 6px;
  }

  .step-navigation::-webkit-scrollbar-thumb {
    background-color: var(--accent-color);
    border-radius: 3px;
  }

  .step-navigation::-webkit-scrollbar-track {
    background-color: var(--light-gray);
  }

  .step-indicator {
    flex: 0 0 auto;
    width: 80px;
  }

  .form-step {
    padding: 15px;
    margin-top: 15px;
  }

  .scroll-indicator {
    display: block;
  }
}

/* ... (rest of the CSS remains the same) */

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .finance-request-container {
    padding: 10px;
  }

  .finance-request-form {
    padding: 15px;
  }

  .form-title {
    font-size: 1.5rem;
  }

  .step-navigation {
    justify-content: flex-start;
  }

  .step-indicator {
    min-width: 50px;
  }

  .step-icon {
    font-size: 20px;
  }

  .step-name {
    font-size: 8px;
  }

  .form-step {
    padding: 15px;
    margin-top: 15px;
  }

  .scroll-indicator {
    display: block;
  }
}

/* Styles for BorrowerList and BorrowerForm */
.borrower-list-container {
  margin-bottom: 2rem;
}

.borrower-card {
  background-color: var(--white);
  border-radius: var(--border-radius);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  margin-bottom: 1rem;
  padding: 1rem;
}

.borrower-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.borrower-form {
  max-width: 100%;
  margin: 20px auto;
  padding: 20px;
  border-radius: var(--border-radius);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: var(--white);
}

.borrower-form h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  text-align: center;
  color: var(--primary-color);
}

.borrower-form .form-group {
  margin-bottom: 1.2rem;
}

.borrower-form label {
  font-size: 1rem;
  font-weight: 600;
  color: var(--secondary-color);
  margin-bottom: 0.5rem;
  display: block;
}

.borrower-form input,
.borrower-form select,
.borrower-form textarea {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border-radius: var(--border-radius);
  border: 1px solid var(--light-gray);
  transition: border-color 0.3s ease;
}

.borrower-form input:focus,
.borrower-form select:focus,
.borrower-form textarea:focus {
  outline: none;
  border-color: var(--accent-color);
  box-shadow: 0 0 0 2px rgba(0, 101, 255, 0.1);
}

.borrower-form .btn {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: var(--border-radius);
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.borrower-form .btn-primary {
  background-color: var(--primary-color);
  color: var(--white);
  border: none;
}

.borrower-form .btn-primary:hover {
  background-color: var(--accent-color);
}

.borrower-form .btn-secondary {
  background-color: var(--light-gray);
  color: var(--secondary-color);
  border: none;
}

.borrower-form .btn-secondary:hover {
  background-color: #e0e0e0;
}

@media (max-width: 480px) {
  .borrower-form {
    padding: 15px;
  }

  .borrower-form h2 {
    font-size: 1.3rem;
  }

  .borrower-form .btn {
    width: 100%;
    margin-bottom: 10px;
  }
}