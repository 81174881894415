.bg-gray-100 { background-color: #f3f4f6; }
.bg-white { background-color: white; }
.text-gray-800 { color: #1f2937; }
.text-gray-700 { color: #374151; }
.text-blue-600 { color: #2563eb; }
.rounded-lg { border-radius: 0.5rem; }
.shadow-md { box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); }
.grid { display: grid; }
.gap-6 { gap: 1.5rem; }
.p-6 { padding: 1.5rem; }
.mb-6 { margin-bottom: 1.5rem; }
.text-3xl { font-size: 1.875rem; line-height: 2.25rem; }
.text-lg { font-size: 1.125rem; line-height: 1.75rem; }
.font-bold { font-weight: 700; }
.font-semibold { font-weight: 600; }

@media (min-width: 640px) {
  .sm\:grid-cols-2 { grid-template-columns: repeat(2, minmax(0, 1fr)); }
}

@media (min-width: 1024px) {
  .lg\:grid-cols-4 { grid-template-columns: repeat(4, minmax(0, 1fr)); }
  .lg\:grid-co
  ls-2 { grid-template-columns: repeat(2, minmax(0, 1fr)); }
}

.dashBoardPadding {
    padding: 5px;
}