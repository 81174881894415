.finance-requests-page {
    font-family: var(--font-family);
    line-height: 1.6;
    color: var(--text-color);
    background-color: var(--background-color);
  }
  
  .hero {
    background: var(--primary-color);
    color: var(--white);
    padding: 4rem 0;
    text-align: center;
  }
  
  .hero h1 {
    font-size: 2.8rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  
  .container {
    max-width: var(--max-width);
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .requests-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
    padding: 2rem 0;
  }
  
  .request-card {
    background-color: var(--white);
    border-radius: var(--border-radius);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
  }
  
  .request-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .request-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }
  
  .status-badge {
    padding: 0.25rem 0.75rem;
    border-radius: 1rem;
    font-size: 0.8rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .status-pending { background-color: #FFA500; color: var(--white); }
  .status-approved { background-color: #36B37E; color: var(--white); }
  .status-declined { background-color: #FF5630; color: var(--white); }
  .status-unknown { background-color: #C1C7D0; color: var(--white); }
  
  .property-address {
    font-size: 1.2rem;
    color: var(--secondary-color);
    margin-bottom: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .request-details {
    flex-grow: 1;
    margin-bottom: 1rem;
  }
  
  .request-details p {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    color: var(--text-color);
  }
  
  .request-details p svg {
    margin-right: 0.5rem;
    color: var(--primary-color);
  }
  
  .view-details-btn,
  .edit-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color);
    color: var(--white);
    padding: 0.5rem 1rem;
    border-radius: var(--border-radius);
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease;
    border: none;
    cursor: pointer;
    width: 100%;
  }
  
  .view-details-btn:hover,
  .edit-btn:hover {
    background-color: var(--accent-color);
  }
  
  .view-details-btn svg,
  .edit-btn svg {
    margin-left: 0.5rem;
  }
  
  .edit-btn {
    background-color: var(--accent-color);
  }
  
  .edit-btn:hover {
    background-color: var(--primary-color);
  }
  
  .pending-message {
    margin-top: 1rem;
    font-size: 0.9rem;
    color: var(--accent-color);
    font-style: italic;
    text-align: center;
  }
  
  .error-message,
  .no-requests {
    text-align: center;
    padding: 3rem 0;
  }
  
  .error-icon,
  .no-requests-icon {
    font-size: 3rem;
    color: var(--primary-color);
    margin-bottom: 1rem;
  }
  
  @media (max-width: 768px) {
    .hero h1 {
      font-size: 2.2rem;
    }
  
    .requests-grid {
      grid-template-columns: 1fr;
    }
  }