.emails-view-container {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .emails-data-grid {
    background-color: #fff;
    border: 1px solid #ddd;
  }
  
  .conversation-container {
    max-height: 500px;
    overflow-y: auto;
  }
  
  .email-message {
    background-color: #fafafa;
  }
  