/* AllMortgagesView.css */
.all-mortgages-view {
    padding: 24px;
    background-color: #f0f2f5;
  }
  
  .all-mortgages-view h2 {
    margin-bottom: 24px;
    color: #1a3353;
  }
  
  .filters {
    display: flex;
    gap: 16px;
    margin-bottom: 24px;
    flex-wrap: wrap;
  }
  
  .filters .ant-input-affix-wrapper,
  .filters .ant-select {
    flex: 1;
    min-width: 200px;
  }
  
  .ant-table-wrapper {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03), 
                0 1px 6px -1px rgba(0, 0, 0, 0.02), 
                0 2px 4px rgba(0, 0, 0, 0.02);
  }
  
  .ant-table-thead > tr > th {
    background-color: #fafafa;
    color: #1a3353;
    font-weight: 600;
  }
  
  .mortgage-details {
    padding: 16px;
  }
  
  .mortgage-details h3 {
    margin-bottom: 16px;
    color: #1a3353;
  }
  
  .mortgage-details p {
    margin-bottom: 8px;
  }
  
  @media (max-width: 768px) {
    .filters {
      flex-direction: column;
    }
  
    .filters .ant-input-affix-wrapper,
    .filters .ant-select {
      width: 100%;
    }
  }