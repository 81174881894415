.review-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  background-color: #f8f9fa;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
  font-size: 28px;
}

.review-group {
  background-color: #ffffff;
  margin-bottom: 30px;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.review-group h3 {
  display: flex;
  align-items: center;
  color: #4a90e2;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 20px;
}

.review-group h3 svg {
  margin-right: 10px;
}

.review-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 1.5;
}

.review-item strong {
  color: #555;
  flex-basis: 40%;
}

.review-item span {
  flex-basis: 60%;
  text-align: right;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.button {
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.button.primary {
  background-color: #4a90e2;
  color: white;
}

.button.primary:hover {
  background-color: #3a7bc8;
}

.button.secondary {
  background-color: #e0e0e0;
  color: #333;
}

.button.secondary:hover {
  background-color: #d0d0d0;
}

@media (max-width: 600px) {
  .review-container {
    padding: 20px;
  }

  .review-item {
    flex-direction: column;
  }

  .review-item strong,
  .review-item span {
    flex-basis: 100%;
    text-align: left;
  }

  .button-group {
    flex-direction: column-reverse;
  }

  .button {
    width: 100%;
    margin-bottom: 10px;
  }
}

/* Modal styles */
.confirm-modal .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}

.confirm-modal .close {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  opacity: 0.5;
  background: none;
  border: none;
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.confirm-modal .close:hover {
  opacity: 0.75;
}

/* Success animation styles */
.success-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.success-animation {
  width: 100px;
  height: 100px;
  background-color: #4CAF50;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: scale-up 0.5s ease-in-out;
}

.success-icon {
  font-size: 50px;
  color: white;
  animation: fade-in 0.5s ease-in-out 0.5s both;
}

@keyframes scale-up {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}