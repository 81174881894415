/* FinancialInformation.css */

.financial-information-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    color: #333;
    margin-bottom: 30px;
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 25px;
  }
  
  label {
    display: block;
    margin-bottom: 8px;
    font-weight: 600;
    color: #555;
    font-size: 14px;
  }
  
  label svg {
    margin-right: 8px;
    color: #4a90e2;
  }
  
  .currency-input {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .currency-symbol {
    position: absolute;
    left: -20px;
    top: 50%;
    transform: translateY(-50%);
    color: #888;
    font-size: 16px;
    pointer-events: none;
  }
  
  .currency-input input {
    width: 100%;
    padding: 12px 12px 12px 5px; /* Adjusted padding */
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    transition: border-color 0.3s ease;
  }
  
  .currency-input input:focus {
    outline: none;
    border-color: #4a90e2;
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
  }
  
  .currency-input input[readonly] {
    background-color: #f9f9f9;
    cursor: not-allowed;
  }
  
  .formatted-value {
    margin-top: 5px;
    font-size: 14px;
    color: #888;
    text-align: right;
  }
  
  .button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }
  
  .button {
    padding: 12px 24px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    transition: background-color 0.3s ease, transform 0.1s ease;
  }
  
  .button:active {
    transform: translateY(1px);
  }
  
  .button.primary {
    background-color: #4a90e2;
    color: white;
  }
  
  .button.primary:hover {
    background-color: #3a7bc8;
  }
  
  .button.secondary {
    background-color: #f0f0f0;
    color: #333;
  }
  
  .button.secondary:hover {
    background-color: #e0e0e0;
  }
  
  @media (max-width: 480px) {
    .financial-information-container {
      padding: 20px;
    }
  
    .button-group {
      flex-direction: column-reverse;
      gap: 10px;
    }
  
    .button {
      width: 100%;
    }
  }