.bd-broker-dashboard {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.bd-dashboard-content {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.bd-sidebar {
  width: 80px;
  background-color: #4a90e2; /* Blue background */
  height: calc(100vh - 60px); /* Adjust based on Navbar height */
  overflow-y: auto;
  transition: width 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align menu options to the top */
}

.bd-main-content {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  transition: margin-left 0.3s ease;
}

.bd-sidebar-nav {
  display: flex;
  flex-direction: column;
  padding: 15px 0px; /* Optional padding */
}

.bd-tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 10px;
  color: white; /* White text */
  text-decoration: none;
  transition: background-color 0.3s ease;
  width: 100%;
  border: none;
  background: none;
  cursor: pointer;
}

.bd-tab:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Slight white overlay on hover */
}

.bd-tab.bd-active {
  background-color: rgba(255, 255, 255, 0.2); /* Slightly more white overlay for active tab */
  font-weight: bold;
}

.bd-tab-icon {
  font-size: 1.5rem;
  margin-bottom: 5px;
  color: white; /* White icon */
}

.bd-tab-label {
  font-size: 0.75rem;
  text-align: center;
  color: white; /* White text */
}

.bd-sidebar.bd-closed + .bd-main-content {
  margin-left: 80px;
}

@media (max-width: 768px) {
  .bd-sidebar {
    position: fixed;
    z-index: 1000;
    left: -80px;
  }

  .bd-sidebar.bd-open {
    left: 0;
  }

  .bd-main-content {
    margin-left: 0 !important;
  }
}