.combined-selection-pills {
    font-family: var(--font-family);
    margin-bottom: 1.5rem;
  }
  
  .main-options,
  .sub-options {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    margin-bottom: 1rem;
  }
  
  .pill {
    padding: 0.75rem 1.25rem;
    border: none;
    border-radius: 30px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Main options styling (previously sub-options) */
  .main-options .pill {
    background-color: var(--white);
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
  }
  
  .main-options .pill:hover,
  .main-options .pill.selected {
    background-color: var(--primary-color);
    color: var(--white);
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Sub-options styling (previously main options) */
  .sub-options .pill {
    background-color: var(--light-gray);
    color: var(--text-color);
  }
  
  .sub-options .pill:hover {
    background-color: var(--accent-color);
    color: var(--white);
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .sub-options .pill.selected {
    background-color: var(--primary-color);
    color: var(--white);
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 768px) {
    .main-options,
    .sub-options {
      flex-direction: column;
      gap: 0.5rem;
    }
  
    .pill {
      width: 100%;
    }
  }