:root {
  --fmv-primary-color: #0052cc;
  --fmv-secondary-color: #172b4d;
  --fmv-accent-color: #00b8d9;
  --fmv-background-color: #f9fafb;
  --fmv-text-color: #172b4d;
  --fmv-border-color: #dfe1e6;
  --fmv-success-color: #36b37e;
  --fmv-warning-color: #ffab00;
  --fmv-danger-color: #ff5630;
  --fmv-section-background: #ffffff;
  --fmv-shadow-color: rgba(0, 0, 0, 0.05);
  --fmv-transition: all 0.3s ease;
}

.fmv__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(9, 30, 66, 0.54);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.fmv__content {
  background-color: var(--fmv-background-color);
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  width: 90%;
  max-width: 1400px;
  height: 90vh;
  display: flex;
  flex-direction: column;
}

.fmv__header {
  background-color: white;
  color: var(--fmv-text-color);
  padding: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fmv__title {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  flex-grow: 1;
}

.fmv__header-actions {
  display: flex;
  gap: 10px;
  margin-right: 20px;
}

.fmv__close-btn {
  background: none;
  border: none;
  color: var(--fmv-text-color);
  font-size: 24px;
  cursor: pointer;
  transition: var(--fmv-transition);
  padding: 5px;
  border-radius: 50%;
}

.fmv__close-btn:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.fmv__body {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.fmv__sidebar {
  width: 80px; /* Match the width of BrokerDashboard */
  background-color: #4a90e2; /* Blue background */
  height: 100%; /* Full height */
  overflow-y: auto;
  transition: width 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align menu options to the top */
}

.fmv__nav-btn {
  display: flex;
  flex-direction: column; /* Align icon and label vertically */
  align-items: center;
  justify-content: center;
  padding: 15px 10px;
  color: white; /* White text */
  text-decoration: none;
  transition: background-color 0.3s ease;
  width: 100%;
  border: none;
  background: none;
  cursor: pointer;
}

.fmv__nav-btn:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Slight white overlay on hover */
}

.fmv__nav-btn--active {
  background-color: rgba(255, 255, 255, 0.2); /* Slightly more white overlay for active tab */
  font-weight: bold;
}

.fmv__nav-icon {
  font-size: 1.5rem; /* Adjust icon size */
  margin-bottom: 5px;
  color: white; /* White icon */
}

.fmv__nav-text {
  font-size: 0.75rem; /* Adjust text size */
  text-align: center;
  color: white; /* White text */
}

.fmv__main {
  flex-grow: 1;
  padding: 30px;
  overflow-y: auto;
}

.fmv__section {
  background-color: var(--fmv-section-background);
  border-radius: 12px;
  box-shadow: 0 4px 8px var(--fmv-shadow-color);
  padding: 30px;
  margin-bottom: 30px;
}

.fmv__section-title {
  font-size: 24px;
  font-weight: bold;
  color: var(--fmv-primary-color);
  margin-bottom: 15px;
}

.fmv__grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 25px;
}

.fmv__info-item {
  font-size: 16px;
  margin-bottom: 12px;
}

.fmv__info-label {
  font-weight: 500;
  color: var(--fmv-secondary-color);
}

.fmv__info-value {
  color: var(--fmv-text-color);
}

.fmv__card {
  background-color: var(--fmv-background-color);
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 6px var(--fmv-shadow-color);
  margin-bottom: 20px;
}

/* Remove the footer styles as it's no longer needed */
.fmv__footer {
  display: none;
}

.fmv__btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: var(--fmv-transition);
  display: flex;
  align-items: center;
}

.fmv__btn-icon {
  margin-right: 8px;
  font-size: 16px;
}

.fmv__btn--approve {
  background-color: var(--fmv-success-color);
  color: white;
}

.fmv__btn--decline {
  background-color: var(--fmv-danger-color);
  color: white;
}

.fmv__btn--export {
  background-color: var(--fmv-accent-color);
  color: white;
}

.fmv__btn--reopen {
  background-color: var(--fmv-warning-color);
  color: white;
}

.fmv__btn:hover {
  opacity: 0.9;
  transform: translateY(-2px);
}

.fmv__btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.fmv__btn-icon--spinning {
  animation: fmv-spin 1s linear infinite;
}

@keyframes fmv-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.fmv__error {
  color: var(--fmv-danger-color);
  background-color: #ffeeee;
  border: 1px solid var(--fmv-danger-color);
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 20px;
  font-size: 14px;
}

/* Broker Insights Styles */
.broker-insights-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 25px;
}

.broker-insights-column .insight-group {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.broker-insights-column .insight-group h3 {
  font-size: 18px;
  font-weight: 600;
  color: var(--fmv-primary-color);
  margin-bottom: 10px;
}

.fmv__btn--complete {
  background-color: #4CAF50; /* Green color for completion */
  color: white;
}

.fmv__btn--complete:hover {
  background-color: #45a049;
}

.fmv__btn--complete:disabled {
  background-color: #a5d6a7;
  cursor: not-allowed;
}