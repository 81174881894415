/* PersonalFinancialStatement.css */

.personal-financial-statement {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.personal-financial-statement h2 {
  text-align: center;
  margin-bottom: 2rem;
  color: #333;
}

.card {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  margin-bottom: 2rem;
}

.card:hover {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.card-header {
  background-color: #f8f9fa;
  border-bottom: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.card-header h3 {
  margin: 0;
  color: #007bff;
}

.card-body {
  padding: 1.5rem;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.form-group label {
  font-weight: bold;
  color: #495057;
  margin-bottom: 0.5rem;
}

.input-group-text {
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  color: #495057;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.btn-danger {
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  background-color: #c82333;
  border-color: #bd2130;
}

@media (max-width: 768px) {
  .personal-financial-statement {
    padding: 1rem;
  }

  .card-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .card-header button {
    margin-top: 1rem;
  }
}
