.lender-filter-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .lender-filter {
    background-color: #fff;
    border-radius: 8px;
    padding: 2rem;
    width: 90%;
    max-width: 500px;
  }
  
  .lender-filter h2 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 1.5rem;
  }
  
  .form-group {
    margin-bottom: 1.5rem;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: #555;
  }
  
  .form-group input[type="number"],
  .form-group input[type="text"],
  .form-group select {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease;
  }
  
  .form-group input[type="number"]:focus,
  .form-group input[type="text"]:focus,
  .form-group select:focus {
    outline: none;
    border-color: #007bff;
  }
  
  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 2rem;
  }
  
  .apply-btn,
  .cancel-btn {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .apply-btn {
    background-color: #007bff;
    color: #fff;
  }
  
  .apply-btn:hover {
    background-color: #0056b3;
  }
  
  .cancel-btn {
    background-color: #6c757d;
    color: #fff;
  }
  
  .cancel-btn:hover {
    background-color: #545b62;
  }