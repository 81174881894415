/* AddressInput.css */
.location-search-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 1rem;
  color: #333;
  outline: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.location-search-input:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.autocomplete-dropdown-container {
  margin-top: 0.5rem;
  background: white;
  border: 1px solid #ced4da;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.suggestion-item {
  padding: 0.75rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.suggestion-item--active,
.suggestion-item:hover {
  background-color: #f8f9fa;
}

.suggestion-item:not(:last-child) {
  border-bottom: 1px solid #e9ecef;
}

/* Adjustments for InputGroup */
.input-group {
  margin-bottom: 0.5rem;
}

.input-group-text {
  background-color: #f8f9fa;
  border-color: #ced4da;
  color: #495057;
}

/* Loading state */
.autocomplete-dropdown-container > div:first-child {
  padding: 0.75rem;
  color: #6c757d;
}