:root {
    --primary-color: #1a73e8;
    --secondary-color: #5f6368;
    --background-color: #f8f9fa;
    --card-background: #ffffff;
    --border-color: #dadce0;
    --text-color: #202124;
    --success-color: #34a853;
    --warning-color: #fbbc05;
    --danger-color: #ea4335;
    --font-family: 'Roboto', sans-serif;
  }
  
  .customer-details-view {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0.5rem;
    font-family: var(--font-family);
    color: var(--text-color);
   
  }
  
  .customer-details-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .back-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    color: var(--secondary-color);
    cursor: pointer;
  }
  
  .customer-name {
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--text-color);
  }
  
  .add-mortgage-button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .add-mortgage-button:hover {
    background-color: #1967d2;
  }
  
  .customer-info {
    display: flex;
    gap: 2rem;
    margin-bottom: 2rem;
  }
  
  .info-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--secondary-color);
  }
  
  .mortgages-title {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  
  .mortgages-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1rem;
  }
  
  .mortgage-card {
    background-color: var(--card-background);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    padding: 1rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  .mortgage-card-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
  }
  
  .mortgage-address {
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
  }
  
  .status-badge {
    font-size: 0.8rem;
    padding: 0.25rem 0.5rem;
    border-radius: 12px;
    font-weight: 500;
  }
  
  .status-pending { background-color: #fff0c2; color: #b45309; }
  .status-submitted { background-color: #e0f2fe; color: #0369a1; }
  .status-declined { background-color: #fee2e2; color: #b91c1c; }
  .status-approved { background-color: #d1fae5; color: #047857; }
  
  .mortgage-details {
    margin-bottom: 1rem;
  }
  
  .detail-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    color: var(--secondary-color);
  }
  
  .progress-container {
    margin-bottom: 1rem;
  }
  
  .progress-bar {
    height: 4px;
    background-color: #e0e0e0;
    border-radius: 2px;
    overflow: hidden;
  }
  
  .progress {
    height: 100%;
    background-color: var(--primary-color);
  }
  
  .progress-text {
    font-size: 0.8rem;
    color: var(--secondary-color);
    display: flex;
    align-items: center;
    gap: 0.25rem;
    margin-top: 0.25rem;
  }
  
  .incomplete-data {
    font-size: 0.8rem;
    color: var(--warning-color);
    display: flex;
    align-items: center;
    gap: 0.25rem;
    margin-bottom: 1rem;
  }
  
  .view-details-button {
    width: 100%;
    background-color: transparent;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    padding: 0.5rem;
    border-radius: 4px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
  
  .view-details-button:hover {
    background-color: var(--primary-color);
    color: white;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
  }
  
  .modal-content {
    background-color: var(--card-background);
    padding: 0.1rem;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease-in-out;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
  
  .modal-header h5 {
    margin: 0;
    font-size: 1.25rem;
    color: var(--text-color);
  }
  
  .modal-body {
    font-size: 1rem;
    color: var(--text-color);
    margin-bottom: 1rem;
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    border-top: 1px solid var(--border-color);
    padding-top: 1rem;
  }
  
  .cancel-button, .confirm-button {
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-size: 0.9rem;
    cursor: pointer;
  }
  
  .cancel-button {
    background-color: transparent;
    border: 1px solid var(--border-color);
    color: var(--secondary-color);
  }
  
  .confirm-button {
    background-color: var(--primary-color);
    border: none;
    color: white;
  }
  
  .loading, .error {
    text-align: center;
    padding: 2rem;
    font-size: 1.2rem;
    color: var(--secondary-color);
  }
  
  .error {
    color: var(--danger-color);
  }
  
  @media (max-width: 768px) {
    .customer-details-view {
      padding: 1rem;
    }
  
    .customer-info {
      flex-direction: column;
      gap: 1rem;
    }
  
    .mortgages-grid {
      grid-template-columns: 1fr;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }