/* src/Components/BorrowerForm.css */
.borrower-form {
  max-width: 100%;
  margin: 20px auto;
  padding: 20px;
  border-radius: var(--border-radius);
  background-color: var(--white);
}

.borrower-form h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  text-align: center;
  color: var(--primary-color);
}

.borrower-form .form-group {
  margin-bottom: 1.2rem;
}

.borrower-form label {
  font-size: 1rem;
  font-weight: 600;
  color: var(--secondary-color);
  margin-bottom: 0.5rem;
  display: block;
}

.borrower-form input,
.borrower-form select,
.borrower-form textarea {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border-radius: var(--border-radius);
  border: 1px solid var(--light-gray);
  transition: border-color 0.3s ease;
}

.borrower-form input:focus,
.borrower-form select:focus,
.borrower-form textarea:focus {
  outline: none;
  border-color: var(--accent-color);
  box-shadow: 0 0 0 2px rgba(0, 101, 255, 0.1);
}

.borrower-form .btn {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: var(--border-radius);
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.borrower-form .btn-primary {
  background-color: var(--primary-color);
  color: var(--white);
  border: none;
}

.borrower-form .btn-primary:hover {
  background-color: var(--accent-color);
}

.borrower-form .btn-secondary {
  background-color: var(--light-gray);
  color: var(--secondary-color);
  border: none;
}

.borrower-form .btn-secondary:hover {
  background-color: #e0e0e0;
}