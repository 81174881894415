/* src/Components/BorrowerList.css */
.borrower-list-container {
  margin-bottom: 2rem;
}

.section-title {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  text-align: center;
}

.borrower-card {
  height: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  border-radius: var(--border-radius);
  overflow: hidden;
}

.borrower-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.borrower-card .card-title {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.borrower-card .card-text {
  font-size: 0.9rem;
  margin-bottom: 1rem;
  color: var(--text-color);
}

.borrower-card .btn {
  margin-right: 0.5rem;
}

.spinner {
  display: block;
  margin: 20px auto;
}

.alert {
  margin-bottom: 1rem;
  border-radius: var(--border-radius);
}

@media (max-width: 768px) {
  .borrower-card {
    margin-bottom: 1rem;
  }
}