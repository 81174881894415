.lenderio-auth-page {
  min-height: 100vh;
  background: linear-gradient(135deg, var(--primary-color), var(--accent-color));
  display: flex;
  flex-direction: column;
}

.lenderio-auth-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.lenderio-auth-card {
  background: var(--white);
  padding: 2rem;
  border-radius: var(--border-radius);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.lenderio-auth-card h2 {
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 2rem;
  font-weight: bold;
}

.lenderio-input-icon {
  position: relative;
  margin-bottom: 1.5rem;
}

.lenderio-input-icon svg {
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
  color: var(--primary-color);
}

.lenderio-input-icon input {
  width: 100%;
  padding: 0.75rem 1rem 0.75rem 3rem;
  border-radius: 30px;
  border: 1px solid var(--light-gray);
  transition: var(--transition);
}

.lenderio-input-icon input:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(0, 82, 204, 0.25);
}

.lenderio-auth-card button {
  width: 100%;
  border-radius: 30px;
  padding: 12px 30px;
  font-weight: bold;
  transition: var(--transition);
  background-color: var(--primary-color);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lenderio-auth-card button:hover:not(:disabled) {
  transform: translateY(-3px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.lenderio-auth-card button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.lenderio-auth-card button svg {
  margin-right: 0.5rem;
}

.alert {
  border-radius: var(--border-radius);
  margin-bottom: 1rem;
}

@media (max-width: 480px) {
  .lenderio-auth-card {
    padding: 1.5rem;
  }

  .lenderio-auth-card h2 {
    font-size: 1.5rem;
  }

  .lenderio-input-icon input {
    font-size: 0.9rem;
  }

  .lenderio-auth-card button {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
}