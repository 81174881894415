/* LoadingScreen.css */

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}

.loading-content {
  text-align: center;
  background-color: white;
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s ease-out;
}

.loading-logo {
  width: 150px;
  margin-bottom: 30px;
  animation: pulse 2s infinite;
}

.loading-spinner-container {
  margin-bottom: 20px;
}

.loading-spinner {
  width: 3rem;
  height: 3rem;
  border-width: 0.25em;
}

.loading-message {
  font-size: 18px;
  color: #333;
  margin-bottom: 0;
  font-weight: 300;
  letter-spacing: 0.5px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 768px) {
  .loading-content {
    padding: 30px;
  }

  .loading-logo {
    width: 120px;
  }

  .loading-message {
    font-size: 16px;
  }
}