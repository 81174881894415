.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px; /* Adjust this value if needed */
  z-index: 1000;
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.logo {
  flex-shrink: 0;
}

.logo a {
  display: block;
}

.logo img {
  height: 40px; /* Adjust this value to match your desired logo height */
  width: auto;
  max-width: 100%;
}

nav {
  display: flex;
  gap: 1.5rem;
  margin-left: auto;
  margin-right: 2rem;
}

nav a {
  color: #333;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

nav a:hover {
  color: #4a90e2;
}

.auth-buttons {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-shrink: 0;
}

.user-info {
  font-weight: 500;
}

.btn {
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 600;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  cursor: pointer;
  border: none;
}

.btn-primary {
  background-color: #4a90e2;
  color: white;
}

.btn-secondary {
  background-color: #f0f0f0;
  color: #333;
}

.btn:hover {
  opacity: 0.9;
}

@media (max-width: 1024px) {
  .navbar .container {
    flex-wrap: wrap;
  }

  nav {
    order: 3;
    width: 100%;
    margin-top: 0.5rem;
    justify-content: center;
  }

  .auth-buttons {
    margin-left: auto;
  }
}

@media (max-width: 768px) {
  .navbar .container {
    flex-direction: column;
    align-items: center;
  }

  .logo {
    margin-bottom: 1rem;
  }

  nav {
    margin-top: 1rem;
    justify-content: center;
  }

  .auth-buttons {
    margin-top: 1rem;
    justify-content: center;
  }
}