/* GeneralOverview.css */

.go-container {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 2rem;
}

.go-title {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 2rem;
  text-align: center;
}

.go-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.go-card {
  background-color: var(--white);
  border-radius: var(--border-radius);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
}

.go-card-icon {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-right: 1rem;
}

.go-card-content {
  flex: 1;
}

.go-card-title {
  font-size: 1rem;
  font-weight: 600;
  color: var(--secondary-color);
  margin-bottom: 0.5rem;
}

.go-card-value {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--text-color);
  margin: 0;
}

.go-charts {
  margin-top: 2rem;
}

.go-charts h3 {
  margin-top: 2rem;
  font-size: 1.5rem;
  color: var(--primary-color);
}

.go-top-customers {
  margin-top: 2rem;
}

.go-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.go-table th,
.go-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.go-table th {
  background-color: var(--secondary-color);
  color: white;
  text-align: left;
}

.go-loading,
.go-error,
.go-no-data {
  text-align: center;
  font-size: 1.2rem;
  color: var(--text-color);
  margin-top: 2rem;
}

.go-error {
  color: var(--accent-color);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .go-container {
    padding: 1rem;
  }

  .go-title {
    font-size: 1.5rem;
  }

  .go-grid {
    grid-template-columns: 1fr;
  }

  .go-card-value {
    font-size: 1.25rem;
  }

  .go-charts {
    margin-top: 1rem;
  }

  .go-charts h3 {
    font-size: 1.25rem;
  }
}
.go-charts-row {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  margin-top: 2rem;
}

.go-chart {
  flex: 1;
  padding: 1rem;
  background-color: var(--white);
  border-radius: var(--border-radius);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .go-charts-row {
    flex-direction: column;
  }
}

