.task-card {
    margin-bottom: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .task-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .task-input {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ced4da;
  }
  
  .bool-options {
    display: flex;
    justify-content: flex-end;
  }
  
  .signature-section {
    margin-top: 20px;
    
  }
  
  .signature-text {
    font-style: italic;
    margin-bottom: 10px;
  }
  
  .submit-button {
    margin-top: 20px;
  }

  .signature-canvas {
    background-color: antiquewhite;
  }

  .currency-prefix {
    margin-left: -25px; /* Adjust as needed */
    position: absolute;
    margin-top: 10px; /* Adjust based on your input field's alignment */
  }
  
  .total-line {
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 10px;
  }
  
  .signature-section + .submit-button {
    margin-top: 20px;
  }
  
  .file-upload-container {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .file-upload-text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .file-upload-icon {
    font-size: 24px;
    margin-top: 10px;
  }