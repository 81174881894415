/* REOTable.css */
.reo-table-container {
    margin-top: 20px;
  }
  
  .reo-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
  }
  
  .reo-table thead th {
    background-color: #f8f9fa;
    border: none;
    padding: 12px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.8rem;
    color: #495057;
  }
  
  .reo-table tbody tr {
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
  }
  
  .reo-table tbody tr:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .reo-table tbody td {
    padding: 15px;
    border: none;
    vertical-align: middle;
  }
  
  .reo-table button {
    margin-right: 5px;
  }
  
  /* BorrowerList.css */
  .borrower-list-container {
    padding: 30px 0;
  }
  
  .section-title {
    font-size: 2rem;
    color: #343a40;
    margin-bottom: 30px;
    border-bottom: 2px solid #007bff;
    padding-bottom: 10px;
  }
  
  .borrower-card {
    border: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .borrower-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
  
  .borrower-card .card-title {
    color: #007bff;
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  
  .borrower-card .card-text {
    margin-bottom: 20px;
  }
  
  .borrower-card .btn {
    margin-right: 10px;
  }