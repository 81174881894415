/* LoanInformation.css */

.loan-information-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.form-title {
  font-size: 28px;
  color: #333;
  margin-bottom: 30px;
  text-align: center;
}

.section-title {
  font-size: 20px;
  color: #4a90e2;
  margin-bottom: 20px;
  border-bottom: 2px solid #4a90e2;
  padding-bottom: 10px;
}

.form-section {
  margin-bottom: 40px;
}

.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.form-group {
  flex: 1;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: #555;
}

.text-input,
.select-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.text-input:focus,
.select-input:focus {
  border-color: #4a90e2;
  outline: none;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

.input-with-icon {
  position: relative;
}

.input-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
}

.input-with-icon input {
  padding-left: 25px;
}

.input-with-icon input[name="requestedLoanAmount"] {
  padding-left: 30px;
}

.input-with-icon .input-icon {
  left: 12px;
  font-size: 18px;
}

.address-section {
  margin-top: 30px;
}

.address-display {
  background-color: #f8f8f8;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 15px;
}

.edit-address {
  margin-top: 10px;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.button {
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.button.primary {
  background-color: #4a90e2;
  color: white;
}

.button.primary:hover {
  background-color: #3a7bc8;
}

.button.secondary {
  background-color: #f0f0f0;
  color: #333;
}

.button.secondary:hover {
  background-color: #e0e0e0;
}

@media (max-width: 768px) {
  .loan-information-container {
    padding: 20px;
  }

  .form-row {
    flex-direction: column;
  }
}