/* FinanaceView.css */

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .main-content {
    margin-top: 80px;
    
  }
  
  .sidebar {
    position: fixed;
    top: 80px;
    left: 0;
    bottom: 0;
    width: 250px;
    padding: 20px;
    background-color: #f8f9fa;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .sidebar .nav-link {
    display: flex;
    align-items: center;
    padding: 15px;
    border-radius: 5px;
    transition: background-color 0.3s;
    color: #333;
    font-weight: 500;
  }
  
  .sidebar .nav-link:hover {
    background-color: #e9ecef;
  }
  
  .sidebar .nav-link.active {
    background-color: #007bff;
    color: #ffffff;
  }
  
  .task-card {
    border: none;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    margin-bottom: 20px;
  }
  
  .task-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  }
  
  .task-card .card-header {
    background-color: #ffffff;
    border-bottom: none;
    padding: 20px;
    font-weight: bold;
    color: #333;
    font-size: 18px;
  }
  
  .task-card .card-body {
    padding: 20px;
  }
  
  .task-card .card-text {
    margin-bottom: 20px;
    color: #666;
    font-size: 16px;
  }
  
  .task-card .form-group label {
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .task-card .form-control {
    border-radius: 5px;
    padding: 12px;
    font-size: 16px;
  }
  
  .task-card .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    transition: background-color 0.3s;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 500;
  }
  
  .task-card .btn-primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }
  
  .task-card .signature-section {
    margin-top: 20px;
  }
  
  .task-card .signature-section canvas {
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  
/* FinanaceView.css */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.main-content {
  margin-top: 60px; /* Adjust this value to match the height of your navbar */
  margin-left: 250px; /* Add this line to push the main content to the right */
}

.sidebar {
  position: fixed;
  top: 60px; /* Adjust this value to match the height of your navbar */
  left: 0;
  bottom: 0;
  width: 250px;
  padding: 20px;
  background-color: #f8f9fa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Rest of the CSS code remains the same */