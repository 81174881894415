:root {
  --primary-color: #4a90e2;
  --secondary-color: #f0f0f0;
  --background-color: #ffffff;
  --text-color: #333333;
  --light-gray: #f9f9f9;
  --border-radius: 8px;
  --transition: all 0.3s ease;
  --font-family: 'Inter', sans-serif;
  --max-width: 1200px;
}

body, h1, h2, h3, p {
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--font-family);
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--background-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 20px;
}

/* Sub-navigation */
.sub-nav {
  background-color: var(--primary-color);
  padding: 0.5rem 0;
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
}

.sub-nav .container {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.sub-nav a {
  color: white;
  text-decoration: none;
  font-weight: 500;
  transition: opacity 0.3s ease;
}

.sub-nav a:hover {
  opacity: 0.8;
}

/* Hero section */
.hero {
  background-color: var(--primary-color);
  color: white;
  padding: 6rem 0;
  text-align: center;
}

.hero h1 {
  font-size: 3rem;
  font-weight: 800;
  margin-bottom: 1rem;
  line-height: 1.2;
}

.hero p {
  font-size: 1.25rem;
  max-width: 600px;
  margin: 0 auto 2rem;
  opacity: 0.9;
}

/* Buttons */
.btn {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border-radius: var(--border-radius);
  text-decoration: none;
  font-weight: 600;
  font-size: 1rem;
  transition: var(--transition);
  cursor: pointer;
  border: none;
}

.btn-primary {
  background-color: white;
  color: var(--primary-color);
}

.btn-primary:hover {
  background-color: var(--secondary-color);
}

/* How It Works section */
.how-it-works {
  padding: 6rem 0;
  background-color: var(--light-gray);
}

.how-it-works h2 {
  text-align: center;
  margin-bottom: 3rem;
  font-size: 2.5rem;
  font-weight: 700;
}

.steps {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.step {
  flex: 1;
  text-align: center;
  padding: 2rem;
  background-color: white;
  border-radius: var(--border-radius);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: var(--transition);
}

.step:hover {
  transform: translateY(-5px);
}

.step-icon {
  font-size: 3rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.step h3 {
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: 600;
}

/* About section */
.about {
  padding: 6rem 0;
  background-color: var(--background-color);
}

.about h2 {
  text-align: center;
  margin-bottom: 3rem;
  font-size: 2.5rem;
  font-weight: 700;
}

.about-content {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.about-item {
  display: flex;
  gap: 2rem;
  align-items: flex-start;
}

.about-icon {
  font-size: 3rem;
  color: var(--primary-color);
  flex-shrink: 0;
}

.about-item h3 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
}

.about-item p {
  font-size: 1.1rem;
  color: #666;
}

/* Contact section */
.contact {
  padding: 6rem 0;
  background-color: var(--light-gray);
  text-align: center;
}

.contact h2 {
  margin-bottom: 3rem;
  font-size: 2.5rem;
  font-weight: 700;
}

.contact-info {
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin-bottom: 3rem;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.contact-icon {
  font-size: 2rem;
  color: var(--primary-color);
}

.contact-item p {
  font-size: 1.1rem;
}

/* Footer */
footer {
  background-color: var(--secondary-color);
  color: var(--text-color);
  text-align: center;
  padding: 1.5rem 0;
}

@media (max-width: 768px) {
  .hero h1 {
    font-size: 2.5rem;
  }
  
  .steps {
    flex-direction: column;
  }
  
  .about-item {
    flex-direction: column;
    text-align: center;
  }
  
  .contact-info {
    flex-direction: column;
    align-items: center;
  }
}