/* FinanceRequestsPage.css */

:root {
  --primary-color: #0052cc; /* Updated primary color */
  --secondary-color: #172b4d; /* Updated secondary color */
  --accent-color: #0065ff; /* Updated accent color for better contrast */
  --background-color: #f4f5f7; /* Light gray background for a clean look */
  --text-color: #172b4d; /* Dark text color for better readability */
  --light-gray: #f4f5f7;
  --white: #ffffff;
  --border-radius: 8px;
  --transition: all 0.3s ease;
  --font-family: 'Arial', sans-serif;
  --max-width: 1200px;
}

body {
  font-family: var(--font-family);
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--background-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.frp-finance-requests-page {
  background-color: var(--background-color);
  min-height: 100vh;
  position: relative;
}

.frp-page-header {
  background: linear-gradient(135deg, var(--primary-color), var(--accent-color));
  color: var(--white);
  padding: 30px 0;
  text-align: center;
}

.frp-page-header h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  font-weight: 700;
}

.frp-page-header p {
  font-size: 1.25rem;
  opacity: 0.9;
}

.frp-container {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 20px;
}

main.frp-container {
  padding-top: 40px;
  padding-bottom: 40px;
}

.frp-new-request-card {
  background-color: var(--white);
  border-radius: var(--border-radius);
  padding: 30px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  margin-bottom: 40px;
}

.frp-new-request-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.frp-new-request-card .frp-icon {
  font-size: 3rem;
  color: var(--primary-color);
  margin-bottom: 20px;
}

.frp-new-request-card h2 {
  margin-bottom: 10px;
  color: var(--text-color);
}

.frp-new-request-card p {
  color: var(--text-color);
}

.frp-requests-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
}

.frp-request-card {
  background-color: var(--white);
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.frp-request-card:hover {
  transform: translateY(-5px);
}

.frp-card-header {
  background-color: var(--primary-color);
  color: var(--white);
  padding: 20px;
  display: flex;
  align-items: center;
}

.frp-card-header .frp-icon {
  font-size: 1.5rem;
  margin-right: 10px;
}

.frp-card-header h3 {
  margin: 0;
  font-size: 1.2rem;
}

.frp-card-body {
  padding: 20px;
}

.frp-card-body p {
  margin-bottom: 10px;
  color: var(--text-color);
}

.frp-inline-icon {
  margin-right: 5px;
  color: var(--primary-color);
}

.frp-status-badge {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: bold;
  text-transform: uppercase;
}

.frp-status-badge.pending {
  background-color: #FFC107;
  color: #000;
}

.frp-status-badge.approved {
  background-color: #4CAF50;
  color: white;
}

.frp-status-badge.rejected {
  background-color: #F44336;
  color: white;
}

.frp-view-details-btn {
  display: block;
  width: 100%;
  padding: 15px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.frp-view-details-btn:hover {
  background-color: #3578c7;
}

.frp-access-denied {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 2rem;
  color: #F44336;
}

.frp-fab {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: white;
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.frp-fab:hover {
  background-color: #3578c7;
}

.frp-fab .frp-fab-icon {
  font-size: 1.5rem;
}
