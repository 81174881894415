/* AdminPanel.css */
.admin-panel {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.admin-body {
  display: flex;
  flex-grow: 1;
  margin-top: 0; /* Ensure there's no gap */
}

.admin-content {
  margin-left: 250px; /* Adjust based on Sidebar width */
  flex-grow: 1;
  padding: 0px;
  background-color: #f8f9fa; /* Light background for content */
}
/* FinanaceView.css */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.main-content {
  margin-top: 60px; /* Adjust this value to match the height of your navbar */
  margin-left: 250px; /* Add this line to push the main content to the right */
}

.sidebar {
  position: fixed;
  top: 60px; /* Adjust this value to match the height of your navbar */
  left: 0;
  bottom: 0;
  width: 250px;
  padding: 20px;
  background-color: #f8f9fa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Rest of the CSS code remains the same */

.bd-broker-dashboard {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.bd-dashboard-content {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.bd-main-content {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  transition: margin-left 0.3s ease;
}

@media (max-width: 768px) {
  .bd-main-content {
    margin-left: 0 !important;
  }
}