.supportive-files-step {
    max-width: 900px;
    margin: 0 auto;
    padding: 40px 20px;
  }
  
  .dropzone {
    border: 3px dashed #007bff;
    border-radius: 10px;
    padding: 60px 40px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 30px;
    transition: all 0.3s ease;
    background-color: #f8f9fa;
  }
  
  .dropzone:hover, .dropzone.active {
    border-color: #0056b3;
    background-color: #e9ecef;
  }
  
  .dropzone-text {
    font-size: 18px;
    color: #495057;
    margin-top: 15px;
  }
  
  .existing-files {
    margin-bottom: 30px;
  }
  
  .file-item {
    transition: all 0.3s ease;
  }
  
  .file-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  }
  
  .file-actions {
    display: flex;
    gap: 10px;
  }
  
  .button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }
  
  .modal-body .file-item {
    margin-bottom: 20px;
  }
  
  .modal-body .form-group {
    margin-bottom: 20px;
  }
  
  .progress {
    height: 25px;
    font-size: 14px;
  }
  
  .current-file {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .file-preview {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-bottom: 15px;
  }
  
  .file-preview svg {
    font-size: 24px;
    margin-right: 10px;
  }
  
  .upload-progress {
    text-align: center;
  }