.bd-sidebar {
  width: 80px;
  background-color: #4a90e2;
  height: calc(100vh - 60px);
  overflow-y: auto;
  transition: width 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.bd-sidebar-nav {
  display: flex;
  flex-direction: column;
  padding: 15px 0px;
}

.bd-tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 10px;
  color: white;
  text-decoration: none;
  transition: background-color 0.3s ease;
  width: 100%;
  border: none;
  background: none;
  cursor: pointer;
}

.bd-tab:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.bd-tab.bd-active {
  background-color: rgba(255, 255, 255, 0.2);
  font-weight: bold;
}

.bd-tab-icon {
  font-size: 1.5rem;
  margin-bottom: 5px;
  color: white;
}

.bd-tab-label {
  font-size: 0.75rem;
  text-align: center;
  color: white;
}

@media (max-width: 768px) {
  .bd-sidebar {
    position: fixed;
    z-index: 1000;
    left: -80px;
  }

  .bd-sidebar.bd-open {
    left: 0;
  }
}